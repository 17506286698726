import React from "react"
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { Section } from '../components/Section'
import Layout from "../components/layout"
import SEO from "../components/seo"
import RequestForm from "../components/RequestForm"
import PatternImg from "../images/line-pattern.png"
import AframeImg from '../images/aframe.png'
import verticalImg from '../images/vertical.png'
import regularImg from '../images/regular.png'
import PointerLeft from '../images/pointer-left.svg'
import '@material/react-select/dist/select.css';
import StickyBox from "react-sticky-box";


class RoofStyles extends React.Component {
  render() {
    const { location } = this.props;
    const formId = "Request a Quote";
    return (
      <Layout location = { location }>
        <SEO title="Roof Styles" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className='position-relative'>
            <Breadcrumb className='beadcrumbs-outer'>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Roof Styles</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className='section carport-solution' pt='100px' pb='100px'>
          <Container>
            <Row>
              <Col lg={7} xl={8}>
                <div className='section-heading'>
                  <span className='section-subtitle'>Building Roofs</span>
                  <h2 className='section-title'>Metal Building Roof Styles</h2>
                  <p>One of the most important decisions you’ll need to make when it comes to choosing your new custom metal building from Carports R Us is deciding which roof style is right for you. All of our roofs are fabricated with strong, galvanized steel, but each of our three available roof styles – the regular roof, the A-frame horizontal roof, and the vertical roof – has its own set of unique features that you’ll want to consider.</p>            
                </div>
                <Section className='section roof-styles-section' pb='100px' pt='100px'>
                  <Container>
                    <Row>
                      <Col xl={12}>                        
                        <div className='roof-styles'>
                          <Row>
                            <Col xl={5}>
                              <div className='circle-shape'>
                                <img className='pointer-left' src={PointerLeft} alt='...' />
                                <span className='building-type'>BEST</span>
                                <img src={verticalImg} className='img-building' alt='...' />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className='section-heading'>
                                <span className='section-subtitle'>Highly Recommended</span>
                                <h4 className='section-title tex-primary title-underline'>Vertical Roof Style</h4>
                                <p>The vertical roof is our premium option, and it enjoys some advantages over the other two roof styles. The vertical roof is built on an A-frame, but unlike the boxed eave roof, the vertical roof comes with more built-in reinforcement, making it our strongest roof. Another unique feature of a vertical roof is the fact that the paneling is oriented vertically, rather than horizontally. This design allows for better flow of precipitation and debris off your roof, and away from your building. Vertical roofs are the best choice for withstanding extreme weather, and we also recommend a vertical roof for any building length of 36’ or greater.</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={5}>
                              <div className='circle-shape'>
                                <img className='pointer-left' src={PointerLeft} alt='...' />
                                <span className='building-type'>Better</span>
                                <img src={AframeImg} className='img-building' alt='...' />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className='section-heading'>
                                <span className='section-subtitle'>Affordable</span>
                                <h4 className='section-title tex-primary title-underline'>A-Frame Horizontal Roof Style</h4>
                                <p>An A-frame (also commonly known as “boxed eave”) roof is different from a regular roof in that it is built on a sturdier A-frame design. Some people also prefer the look of an A-frame roof because it tends to more closely resemble the roof style of a typical home. Like the regular roof, the paneling on an A-frame roof is oriented horizontally. The A-frame is a better choice for a larger building than a regular roof, and A-frame roofs also do better in areas that experience a little more in terms of wind and precipitation.</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={5}>
                              <div className='circle-shape'>
                                <img className='pointer-left' src={PointerLeft} alt='...' />
                                <span className='building-type'>Good</span>
                                <img src={regularImg} className='img-building' alt='...' />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className='section-heading'>
                                <span className='section-subtitle'>Budget</span>
                                <h4 className='section-title tex-primary title-underline'>Regular Roof Style</h4>
                                <p>Our regular roof has a classic, rounded look that is somewhat reminiscent of a traditional barn. The curvature of the roof paneling extends down the side a bit more than with other roof styles, and is a very common choice for standard carports. The regular roof is also our most inexpensive option as it is our simplest design, having no need for a hat channel or any its associated reinforcements. Paneling on a regular roof runs horizontally from front to back, and these roofs are better suited to areas that tend to experience milder precipitation and winds.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Section>
              </Col>
              <Col lg={5} xl={4}>
                <StickyBox offsetTop={170}>
                  <div className="form-wrapper">
                    <h2 className='form-title'>Request a Quote</h2>                    
                    <RequestForm location = { location } formId = { formId } />
                  </div>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default RoofStyles
